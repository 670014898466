import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useEffect } from "react";
import ReactDataTable from "../../../components/table/ReactDataTable";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  Col,
  ModalComponent,
  TextInput,
  Row,
  DatePickerInput,
  TimePickerInput,
  CheckboxInput,
} from "../../../components/Components";
import { checkInOutLogColumns } from "./DataColumns";
import { useGetGoalFrequenciesQuery } from "../../../redux/api/goal-management/goalApi";
import {
  onChangePageState,
  toggleModal,
  onChangeSearchText,
  onFormCancel,
  onChangeClearSelectedRows,
  onSelectedRowsChange,
} from "../../../redux/features/app-portal/logs/checkInOutLogSlice";
import { Spinner } from "reactstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  useCreateAttendancesMutation,
  useDeleteAttendancesMutation,
  useGetAttendancesQuery,
  useUpdateAttendancesMutation,
} from "../../../redux/api/support-management/supportApi";
import { useLazyGetAppUsersQuery } from "../../../redux/api/users/usersApi";
import {
  AsyncSelectField,
  SelectField,
} from "../../../components/form/SelectField";
import { actionOptions, checkInOutPurposeOptions, purposeOptions } from "../../../constants";
import { act } from "react";

const CheckInOutLog = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.checkInOutLog);
  const { data, isFetching, isSuccess } = useGetAttendancesQuery({
    offset: state.offset,
    limit: state.itemsPerPage,
    total: true,
    reverse: true,
    search_text: state.searchText,
  });
  const defaultValues = {
    email: "",
    purpose: checkInOutPurposeOptions[0],
    type: actionOptions[0],
    checked_in_time: null,
    is_check_in_far: false,
    checked_out_time: null,
    is_check_out_far: false,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm(defaultValues);
  const [
    createAttendance,
    {
      isLoading: isCreateLoading,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateAttendancesMutation();
  const [
    updateAttendance,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateAttendancesMutation();
  const [
    deleteAttendance,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteAttendancesMutation();
  const [getAppUsers, { data: appUsers, isFetching: isGetAppUsersFetching }] =
    useLazyGetAppUsersQuery();
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const changePageState = ({ offset = 0, itemsPerPage }) => {
    dispatch(onChangePageState({ offset, itemsPerPage: itemsPerPage }));
  };

  useLayoutEffect(() => {
    if (data) {
      setTableData(data.attendances);
      setTotalItems(data.total);
    }
  }, [data]);

  const onAddBtnClicked = (e) => {
    dispatch(toggleModal({ type: "add" }));
    reset(defaultValues);
  };

  const processFormData = (data) => {
    data.type = data.type.value;
    data.purpose = data.purpose.value;
    data.date_time =
      data.date && data.time
        ? new Date(
            data.date.getFullYear(),
            data.date.getMonth(),
            data.date.getDate(),
            data.time.getHours(),
            data.time.getMinutes()
          ).toISOString()
        : null;
    data.latitude = parseFloat(data.latitude);
    data.longitude = parseFloat(data.longitude);
    return data;
  };

  const onFormSubmit = (data) => {
    let newData = processFormData(data);
    if (state.modal.add) {
      createAttendance(newData);
    } else if (state.modal.edit) {
      updateAttendance({
        params: { id: state.modal.item.id },
        data: newData,
      });
    }
  };

  const onRowClicked = (row, e) => {
    e.stopPropagation();
    const action = e.target.getAttribute("data-action");
    // eslint-disable-next-line default-case
    switch (action) {
      case "remove":
        deleteAttendance({
          ids: [row.id],
        });
        break;
      case "details":
        dispatch(toggleModal({ type: "details", item: row }));
        break;
      case "edit":
        dispatch(toggleModal({ type: "edit", item: row }));
        reset({
          ...row,
          type: {
            label: actionOptions.find((x) => x.value === row.type)?.label,
            value: row.type,
          },
          purpose: {
            label: checkInOutPurposeOptions.find((x) => x.value === row.purpose)?.label,
            value: row.purpose,
          },
          date: moment(row.date_time).toDate(),
          time: moment(row.date_time).toDate(),
        });
        break;
    }
  };

  useEffect(() => {
    if (isCreateSuccess) {
      dispatch(toggleModal({ type: "add" }));
      reset();
    }
    if (isCreateError && createError) {
      toast.error(createError?.data?.message);
    }
  }, [isCreateSuccess, isCreateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(toggleModal({ type: "edit" }));
      reset();
    }
    if (isUpdateError && updateError) {
      toast.error(updateError?.data?.message);
    }
  }, [isUpdateError, isUpdateSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      if (state.modal.removeItems) {
        dispatch(toggleModal({ type: "removeItems" }));
        dispatch(onChangeClearSelectedRows(!state.clearSelectedRows));
      } else {
        if (deleteData && deleteData.data) {
          let newSelectedRows = state.selectedRows.filter(
            (row) => row.id !== deleteData.data[0]
          );
          dispatch(onSelectedRowsChange(newSelectedRows));
        }
      }
    }
    if (isDeleteError && deleteError) {
      toast.error(deleteError?.data?.message);
    }
  }, [isDeleteSuccess, isDeleteError]);

  const handleSelectedRowsChange = ({ selectedRows }) => {
    dispatch(onSelectedRowsChange(selectedRows));
  };

  const handleApplySelectedRows = (row) => {
    return (
      state.selectedRows?.filter((selectedRow) => selectedRow.id === row.id)
        .length > 0
    );
  };

  const loadUsersOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) return;
    let response = await getAppUsers(
      { offset: 0, limit: 0, search_text: inputValue, is_pure: true },
      true
    ).unwrap();

    if (response.users) {
      const options = response.users.map((item) => {
        let label = "";
        if (item.user.first_name) {
          label += item.user.first_name;
        }
        if (item.user.last_name) {
          label += " " + item.user.last_name;
        }
        label += " - " + item.user.email;

        return { label: label, value: item.user.email };
      });
      callback(options);
    }
  };

  return (
    <>
      <Head title="Check in/out log" />
      <Content>
        <Block>
          <BlockHead>
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Check in/out log</BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <Button
                    className="toggle btn-icon d-md-none"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                  </Button>
                  <Button
                    className="toggle d-none d-md-inline-flex"
                    color="primary"
                    onClick={onAddBtnClicked}
                  >
                    <Icon name="plus" />
                    <span>Add Log</span>
                  </Button>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          <PreviewCard>
            <ReactDataTable
              data={tableData}
              columns={checkInOutLogColumns}
              offset={state.offset}
              itemsPerPage={state.itemsPerPage}
              pagination
              totalItems={totalItems}
              progressPending={isFetching}
              className="nk-tb-list"
              selectableRows
              changePageState={changePageState}
              onRowClicked={onRowClicked}
              placeholderSearchText={"Search by for email"}
              searchFunc={(searchText) => {
                dispatch(onChangeSearchText(searchText));
              }}
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={state.clearSelectedRows}
              selectableRowSelected={handleApplySelectedRows}
            />
          </PreviewCard>
        </Block>

        <ModalComponent
          isOpen={state.modal.details}
          toggle={() => dispatch(toggleModal({ type: "details" }))}
          title={"Account Details"}
          onModalCancel={() => {
            dispatch(onFormCancel());
          }}
        >
          {state.modal.item && (
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text">Attendance Id</span>
                <span className="caption-text">{state.modal.item.id}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Email</span>
                <span className="caption-text">{state.modal.item.email}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Checked in</span>
                <span className="caption-text">
                  {state.modal.item.checked_in_date_time
                    ? moment(state.modal.item.checked_in_date_time).format(
                        "DD-MM-YYYY, hh:mm:ss a"
                      )
                    : ""}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Checked out</span>
                <span className="caption-text">
                  {state.modal.item.checked_out_date_time
                    ? moment(state.modal.item.checked_out_date_time).format(
                        "DD-MM-YYYY, hh:mm:ss a"
                      )
                    : ""}
                </span>
                {/* <span
                    className={`dot bg-${
                      state.modal.item?.is_checked_out ? "success" : "danger"
                    } d-mb-none`}
                  ></span>
                  <span
                    className={`badge badge-sm badge-dot has-bg badge-${
                      state.modal.item.is_checked_out ? "success" : "danger"
                    } d-none d-mb-inline-flex`}
                  >
                    {state.modal.item.is_checked_out
                      ? "Checked out"
                      : "Not checked out"}
                  </span> */}
              </Col>
              <Col lg={6}>
                <span className="sub-text">Checked in far</span>
                <span className="caption-text">
                  {state.modal.item.is_check_in_far.toString()}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Checked out far</span>
                <span className="caption-text">
                  {state.modal.item.is_check_out_far?.toString()}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Time</span>
                <span className="caption-text">
                  {state.modal.item.created_time}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Last Updated Time</span>
                <span className="caption-text">
                  {state.modal.item.last_updated_time}
                </span>
              </Col>
            </Row>
          )}
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.add || state.modal.edit}
          title={`${state.modal.add ? "Add" : "Edit"} Log`}
          onModalCancel={() => {
            dispatch(onFormCancel());
            reset();
          }}
          toggle={() => {
            dispatch(onFormCancel());
            reset();
          }}
          backdrop={"static"}
        >
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <Row className="g-3">
              <Col size="12">
                <label className="form-label">
                  User <span className="required-asterisk">*</span>
                </label>
                <AsyncSelectField
                  defaultValue={
                    state.modal.add
                      ? null
                      : {
                          label: `${state.modal.item?.first_name} ${state.modal.item?.last_name} - ${state.modal.item?.email}`,
                          value: state.modal.item?.email,
                        }
                  }
                  isLoading={isGetAppUsersFetching}
                  onChangeFunc={(newValue) => {
                    setValue("email", newValue.value);
                  }}
                  loadOptions={async (inputValue, callback) => {
                    await loadUsersOptions(inputValue, callback);
                  }}
                  noOptionsMessage={(data) => {
                    if (data.inputValue.length >= 3) {
                      return "No results found";
                    }
                    return "Minimum 3 characters required";
                  }}
                  cacheOptions
                />
              </Col>
              <SelectField
                name={"type"}
                label={"Action"}
                control={control}
                rules={{ required: "This field is required" }}
                options={actionOptions}
                defaultValue={actionOptions[0]}
                errors={errors}
              />
              <SelectField
                name={"purpose"}
                label={"Purpose"}
                control={control}
                rules={{ required: "This field is required" }}
                options={checkInOutPurposeOptions}
                defaultValue={checkInOutPurposeOptions[0]}
                errors={errors}
              />
              <DatePickerInput
                name={"date"}
                label={"Date"}
                placeholder={"dd/mm/yyyy"}
                dateFormat={"dd/MM/yyyy"}
                rules={{ required: "This field is required" }}
                control={control}
                errors={errors}
              />
              <TimePickerInput
                name={"time"}
                label={"Time"}
                placeholder={"hh:mm"}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                control={control}
                rules={{ required: "This field is required" }}
                errors={errors}
              />
              <TextInput
                name={"latitude"}
                label={"Latitude"}
                required
                register={{
                  ...register("latitude", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
              />
              <TextInput
                name={"longitude"}
                label={"Longitude"}
                required
                register={{
                  ...register("longitude", {
                    required: "This field is required",
                  }),
                }}
                errors={errors}
              />

              <Col size="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={isCreateLoading || isUpdateLoading}
                >
                  {(isCreateLoading || isUpdateLoading) && (
                    <Spinner type="grow" size="sm" />
                  )}
                  <span>{state.modal.add ? "Add" : "Edit"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </ModalComponent>

        <ModalComponent
          isOpen={state.modal.removeItems}
          backdrop={"static"}
          title={"Are you sure you want to remove selected items?"}
          onModalCancel={() => {
            dispatch(toggleModal({ type: "removeItems" }));
          }}
          disableCloseBtn={true}
          size={"xs"}
        >
          <Row className="gy-3">
            <Col size="12">
              <Button
                color="primary"
                type="submit"
                disabled={isDeleteLoading}
                onClick={() => {
                  deleteAttendance({
                    ids: state.selectedRows.map((row) => row.id),
                  });
                }}
              >
                {isDeleteLoading && <Spinner type="grow" size="sm" />}
                <span>Yes</span>
              </Button>
              <Button
                className="ml-3"
                color="light"
                onClick={() => {
                  dispatch(toggleModal({ type: "removeItems" }));
                }}
              >
                <span>No</span>
              </Button>
            </Col>
          </Row>
        </ModalComponent>
      </Content>
    </>
  );
};

export default CheckInOutLog;
