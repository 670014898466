import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  FormGroup,
  UncontrolledDropdown,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTablePagination from "../pagination/DataTablePagination";
import { Icon, RSelect, Col, Row } from "../Components";
import { useDispatch } from "react-redux";

const ExpandableRowComponent = ({ data }) => {
  return (
    <ul className="dtr-details p-2 border-bottom ml-1">
      <li className="d-block d-sm-none">
        <span className="dtr-title">Company</span>
        <span className="dtr-data">{data.company}</span>
      </li>
      <li className="d-block d-sm-none">
        <span className="dtr-title ">Gender</span>
        <span className="dtr-data">{data.gender}</span>
      </li>
      <li>
        <span className="dtr-title">Start Date</span>
        <span className="dtr-data">{data.startDate}</span>
      </li>
      <li>
        <span className="dtr-title">Salary</span>
        <span className="dtr-data">{data.salary}</span>
      </li>
    </ul>
  );
};

export const BatchMenuComponent = ({ getState, onRemoveItemsClicked }) => {
  const state = getState();
  const dispatch = useDispatch();

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
      >
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu right>
        <ul className="link-list-opt no-bdr">
          <li>
            <DropdownItem
              tag="a"
              href="#remove-items"
              onClick={(ev) => {
                ev.preventDefault();
                onRemoveItemsClicked(state, dispatch);
              }}
            >
              <Icon name="trash"></Icon>
              <span>Remove Selected Items</span>
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input form-control"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const ReactDataTable = ({
  keyField,
  data,
  columns,
  pagination,
  totalItems,
  offset,
  itemsPerPage,
  className,
  selectableRows,
  onSelectedRowsChange,
  expandableRows,
  progressPending,
  filterFunc,
  placeholderSearchText,
  searchFunc,
  changePageState,
  onRowClicked,
  filterComponent,
  clearSelectedRows,
  selectableRowSelected,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageS, setRowsPerPage] = useState(itemsPerPage ?? 10);
  const initialCurrentPageS = offset / itemsPerPage + 1;
  const [currentPageS, setCurrentPage] = useState(initialCurrentPageS);
  const [mobileView, setMobileView] = useState();
  // const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const FilterComponent = filterComponent;

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (searchFunc) {
      searchFunc(searchText);
    }
    // if (filterFunc) {
    //   let defaultData = tableData;
    //   if (searchText !== "") {
    //     defaultData = data.filter((item) => {
    //       //   return item.name.toLowerCase().includes(searchText.toLowerCase());
    //       return filterFunc(item, searchText);
    //     });
    //     setTableData(defaultData);
    //   } else {
    //     setTableData(data);
    //   }
    // }
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  const onChangeItemsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    changePageState({ itemsPerPage: e.target.value });
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClearRows = () => {};

  return (
    <div
      className={`dataTables_wrapper dt-bootstrap4 no-footer ${
        className ? className : ""
      }`}
    >
      <Row className={`justify-between g-2`}>
        <Col className="col-7 text-left" sm="4">
          {searchFunc && (
            <div id="DataTables_Table_0_filter" className="dataTables_filter">
              <label style={{ width: "100%" }}>
                <input
                  type="search"
                  className="form-control form-control-sm"
                  placeholder={placeholderSearchText ?? ""}
                  onChange={(ev) => setSearchText(ev.target.value)}
                />
              </label>
            </div>
          )}
        </Col>
        <Col className="col-5 text-right" sm="8">
          <div className="datatable-filter">
            <div className="d-flex justify-content-end g-2">
              {filterComponent && <FilterComponent />}
                <div
                  className="dataTables_length"
                  id="DataTables_Table_0_length"
                >
                <label>
                  <span className="d-none d-sm-inline-block">Show</span>
                  <div className="form-control-select">
                    <select
                      name="DataTables_Table_0_length"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={onChangeItemsPerPage}
                      value={rowsPerPageS}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <DataTable
        keyField={keyField}
        data={tableData}
        columns={columns}
        className={className}
        selectableRows={selectableRows}
        selectableRowsComponent={CustomCheckbox}
        onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={clearSelectedRows}
        selectableRowSelected={selectableRowSelected}
        expandableRows={mobileView}
        expandableRowsComponent={ExpandableRowComponent}
          noDataComponent={
            <div className="p-2">There are no records found</div>
          }
        // sortIcon={
        //   <div>
        //     <span>&darr;</span>
        //     <span>&uarr;</span>
        //   </div>
        // }
        onRowClicked={onRowClicked}
        // highlightOnHover
        // pointerOnHover
        persistTableHead={false}
        progressPending={progressPending}
        progressComponent={
          <div className="pb-4 pt-4">
            <Spinner />
          </div>
        }
        pagination={pagination}
        paginationComponent={({
          currentPage,
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
        }) => {
          return (
            <DataTablePagination
              customItemPerPage={rowsPerPageS}
              itemPerPage={rowsPerPageS}
              totalItems={totalItems}
              paginate={onChangePage}
              currentPage={currentPageS}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setCurrentPage={setCurrentPage}
              changePageState={changePageState}
            />
          );
        }}
      ></DataTable>
    </div>
  );
};

export default ReactDataTable;
