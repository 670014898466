import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import { Icon, TooltipComponent } from "../../../components/Components";

export const checkInOutLogColumns = [
  {
    name: "Name",
    selector: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return name;
    },
    sortable: true,
    minWidth: "120px",
    cell: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return <span>{name}</span>;
    },
    hide: "md",
  },
  {
    name: "Action",
    selector: (row) => row.type,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.type}</span>,
    hide: "md",
  },
  {
    name: "Purpose",
    selector: (row) => row.purpose,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.purpose}</span>,
    hide: "md",
  },
  {
    name: "Location",
    minWidth: "120px",
    cell: (row) => {
      return (
        <div>
          <div>
            <span>{`Latitude: ${row.latitude}`}</span>
          </div>
          <div>
            <span>{`Longitude: ${row.longitude}`}</span>
          </div>
        </div>
      );
    },
    hide: "md",
  },
  {
    name: "Date time",
    selector: (row) => row.date_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => row.date_time ? <span>{moment(row.date_time).format("DD-MM-YYYY, hh:mm:ss a")}</span> : "",
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  }
];

export const emotionalLogColumns = [
  // {
  //   name: "User ID",
  //   selector: (row) => row.id,
  //   sortable: true,
  //   compact: true,
  //   style: { paddingRight: "20px" },
  //   cell: (row) => <span>{row.id}</span>,
  // },
  {
    name: "Name",
    selector: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return name;
    },
    sortable: true,
    minWidth: "120px",
    cell: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return <span>{name}</span>;
    },
    hide: "md",
  },
  {
    name: "Emotion",
    selector: (row) => row.type,
    sortable: true,
    minWidth: "120px",
    cell: (row) => <span>{row.type}</span>,
    hide: "md",
  },
  {
    name: "Description",
    selector: (row) => row.description,
    minWidth: "120px",
    cell: (row) => <span>{row.description}</span>,
    hide: "md",
  },
  {
    name: "Date time",
    selector: (row) => row.date_time,
    sortable: true,
    minWidth: "120px",
    cell: (row) => row.date_time ? <span>{moment(row.date_time).format("DD-MM-YYYY, hh:mm:ss a")}</span> : "",
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#edit"
                      data-tag="allowRowEvents"
                      data-action="edit"
                    >
                      <Icon
                        name="edit"
                        data-tag="allowRowEvents"
                        data-action="edit"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="edit">
                        Edit
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  }
];

export const locationLogColumns = [
  {
    name: "Date time",
    selector: (row) => row.created_time,
    sortable: true,
    minWidth: "150px",
    compact: true,
    cell: (row) => (
      <span>{moment(row.created_time).format("DD-MM-YYYY, hh:mm:ss a")}</span>
    ),
    hide: "md",
  },
  // {
  //   name: "User ID",
  //   // selector: (row) => row.id,
  //   style: { paddingRight: "20px" },
  //   cell: (row) => <span>{row.id}</span>,
  // },
  {
    name: "Name",
    selector: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return name;
    },
    sortable: true,
    minWidth: "150px",
    cell: (row) => {
      let name = "";
      if (row.first_name) {
        name += `${row.first_name} `;
      }
      if (row.last_name) {
        name += row.last_name;
      }
      return <span>{name}</span>;
    },
    hide: "md",
  },
  {
    name: "Address",
    selector: (row) => row.address,
    minWidth: "200px",
    cell: (row) => <span>{row.address}</span>,
    hide: "md",
  },
  {
    name: "Latitude",
    selector: (row) => row.coordinates.latitude,
    minWidth: "120px",
    cell: (row) => <span>{row.coordinates.latitude}</span>,
    hide: "md",
  },
  {
    name: "Longitude",
    selector: (row) => row.coordinates.longitude,
    minWidth: "120px",
    cell: (row) => <span>{row.coordinates.longitude}</span>,
    hide: "md",
  },
  {
    button: true,
    cell: (row) => (
      <div className="nk-tb-col nk-tb-col-tools" data-tag="allowRowEvents">
        <ul className="nk-tb-actions gx-1">
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"view" + row.id}
              icon="eye"
              direction="top"
              text="View Details"
              data-tag="allowRowEvents"
              data-action="details"
            />
          </li>
          <li className="nk-tb-action-hidden">
            <TooltipComponent
              tag="a"
              containerClassName="btn btn-trigger btn-icon"
              id={"open-map-" + row.id}
              icon="map"
              direction="top"
              text="Open map"
              data-tag="allowRowEvents"
              data-action="open-map"
            />
          </li>
          <li>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="btn btn-trigger dropdown-toggle btn-icon mr-n1"
              >
                <Icon name="more-h"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#markasdone"
                      data-tag="allowRowEvents"
                      data-action="details"
                    >
                      <Icon
                        name="eye"
                        data-tag="allowRowEvents"
                        data-action="details"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="details">
                        View Details
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#openmap"
                      data-tag="allowRowEvents"
                      data-action="open-map"
                    >
                      <Icon
                        name="map"
                        data-tag="allowRowEvents"
                        data-action="open-map"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="open-map">
                        Open Map
                      </span>
                    </DropdownItem>
                  </li>
                  <li>
                    <DropdownItem
                      tag="a"
                      href="#remove"
                      data-tag="allowRowEvents"
                      data-action="remove"
                    >
                      <Icon
                        name="trash"
                        data-tag="allowRowEvents"
                        data-action="remove"
                      ></Icon>
                      <span data-tag="allowRowEvents" data-action="remove">
                        Remove
                      </span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </div>
    ),
  },
];
