import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { api } from "./api/api";
import accountSlice from "./features/accountSlice";
import authSlice from "./features/authSlice";
import quotesSlice from "./features/quotesSlice";
import goalSlice from "./features/goalSlice";
import goalFrequencySlice from "./features/goalFrequencySlice";
import attendanceSlice from "./features/attendanceSlice";
import supportMetricsSlice from "./features/supportMetricsSlice";
import supportLogsSlice from "./features/supportLogsSlice";
import locationTrackingSlice from "./features/locationTrackingSlice";
import emotionTypesSlice from "./features/emotionTypesSlice";
import emotionalLogsSlice from "./features/emotionalLogsSlice";
import employmentsSlice from "./features/employmentSlice";
import workplacesSlice from "./features/workplacesSlice";
import proSchedulesSlice from "./features/proSchedulesSlice";
import candidateSlice from "./features/candidateSlice";
import adminIntroductionFormSlice from "./features/adminIntroductionFormSlice";
import adminAssessmentFormSlice from "./features/adminAssessmentFormSlice";
import sexSlice from "./features/dropdown-data/sexSlice";
import religionSlice from "./features/dropdown-data/religionSlice";
import formEmailSendingSlice from "./features/config/emailSendingSlice";
import contactUsSubmissionsSlice from "./features/web-form-submissions/contactUsSlice";
import careersSubmissionsSlice from "./features/web-form-submissions/careersSlice";
import uploaderSlice from "./features/upload/uploaderSlice";
import caregiverSlice from "./features/caregiverSlice";
import interactionTypeSlice from "./features/dropdown-data/interactionTypeSlice";
import medicalTypeSlice from "./features/dropdown-data/medicalTypeSlice";
import orgSupportingDocTypeSlice from "./features/dropdown-data/orgSupportingDocTypeSlice";
import raceSlice from "./features/dropdown-data/raceSlice";
import educationLevelSlice from "./features/dropdown-data/educationLevelSlice";
import orgContactRoleSlice from "./features/dropdown-data/orgContactRoleSlice";
import organisationSlice from "./features/organisationSlice";
import opportunityTypeSlice from "./features/dropdown-data/opportunityTypeSlice";
import opportunityContractTypeSlice from "./features/dropdown-data/opportunityContractTypeSlice";
import opportunityCommitmentSlice from "./features/dropdown-data/opportunityCommitmentSlice";
import permissionSlice from "./features/authorization/permissionSlice";
import roleSlice from "./features/authorization/roleSlice";
import metricSoftSkillsSlice from "./features/metrics-assessment/softSkillsSlice";
import metricHardSkillsSlice from "./features/metrics-assessment/hardSkillsSlice";
import metricMotorSkillsSlice from "./features/metrics-assessment/motorSkillsSlice";
import metricOtherSkillsSlice from "./features/metrics-assessment/otherSkillsSlice";
import observationGradingSlice from "./features/dropdown-data/observationGradingSlice";
import userSlice from "./features/userSlice";
import menuDataSlice from "./features/config/menuDataSlice";
import chatSlice from "./features/chat/chatSlice";
import chatbotFlowSlice from "./features/chatbot/chatbotFlowSlice";
import intentSlice from "./features/chatbot/intentSlice";
import intentDetailsSlice from "./features/chatbot/intentDetailsSlice";
import employmentOverviewSlice from "./features/app-portal/employment/employmentOverviewSlice";
import employmentDetailsSlice from "./features/app-portal/employment/employmentDetailsSlice";
import employmentSupportSlice from "./features/app-portal/employment/employmentSupportSlice";
import reminderAndNotificationSlice from "./features/app-portal/support/reminderAndNotificationSlice";
import professionalToUserSlice from "./features/app-portal/support/professionalToUserSlice";
import checkInOutLogSlice from "./features/app-portal/logs/checkInOutLogSlice";
import emotionalLogSlice from "./features/app-portal/logs/emotionalLogSlice";
import locationLogSlice from "./features/app-portal/logs/locationLogSlice";
import appProfessionalSlice from "./features/app-portal/accounts/professionalSlice";
import appUserSlice from "./features/app-portal/accounts/userSlice";
import appOrganisationsSlice from "./features/app-portal/organisations/appOrganisationSlice";
import communityPostSlice from "./features/app-portal/support/communitySlice";
import appConfigLocationTrackingSlice from "./features/app-portal/config/locationTrackingSlice";
import subcriptionSlice from "./features/app-portal/subcriptions/subcriptionSlice";
import accountSubcriptionSlice from "./features/app-portal/subcriptions/accountSubcriptionSlice";

const combinedReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  account: accountSlice,
  quotes: quotesSlice,
  goal: goalSlice,
  goalFrequency: goalFrequencySlice,
  attendance: attendanceSlice,
  supportMetrics: supportMetricsSlice,
  supportLogs: supportLogsSlice,
  locationTrackings: locationTrackingSlice,
  emotionTypes: emotionTypesSlice,
  emotionalLogs: emotionalLogsSlice,
  employments: employmentsSlice,
  workplaces: workplacesSlice,
  proSchedules: proSchedulesSlice,
  auth: authSlice,
  organisations: organisationSlice,
  candidates: candidateSlice,
  caregivers: caregiverSlice,
  introductionForms: adminIntroductionFormSlice,
  assessmentForms: adminAssessmentFormSlice,
  sex: sexSlice,
  religion: religionSlice,
  interactionType: interactionTypeSlice,
  medicalType: medicalTypeSlice,
  race: raceSlice,
  educationLevel: educationLevelSlice,
  orgContactRole: orgContactRoleSlice,
  orgSupportingDocType: orgSupportingDocTypeSlice,
  opportunityType: opportunityTypeSlice,
  opportunityContractType: opportunityContractTypeSlice,
  opportunityCommitment: opportunityCommitmentSlice,
  emailSending: formEmailSendingSlice,
  careersSubmissions: careersSubmissionsSlice,
  contactUsSubmissions: contactUsSubmissionsSlice,
  uploader: uploaderSlice,
  permission: permissionSlice,
  role: roleSlice,
  metricSoftSkill: metricSoftSkillsSlice,
  metricHardSkill: metricHardSkillsSlice,
  metricMotorSkill: metricMotorSkillsSlice,
  metricOtherSkill: metricOtherSkillsSlice,
  observationGrading: observationGradingSlice,
  users: userSlice,
  menuData: menuDataSlice,
  chat: chatSlice,
  chatbotFlowSlice: chatbotFlowSlice,
  intent: intentSlice,
  intentDetails: intentDetailsSlice,
  employmentOverview: employmentOverviewSlice,
  employmentDetails: employmentDetailsSlice,
  employmentSupport: employmentSupportSlice,
  reminderAndNotification: reminderAndNotificationSlice,
  professionalToUser: professionalToUserSlice,
  checkInOutLog: checkInOutLogSlice,
  emotionalLog: emotionalLogSlice,
  locationLog: locationLogSlice,
  appProfessional: appProfessionalSlice,
  appUser: appUserSlice,
  appOrganisations: appOrganisationsSlice,
  communityPost: communityPostSlice,
  appConfigLocationTracking: appConfigLocationTrackingSlice,
  subcription: subcriptionSlice,
  accountSubcription: accountSubcriptionSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logoutUser") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
